import React, { createContext, useContext } from 'react';
import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';
import { navigate } from 'gatsby';
import Loading from 'src/components/design-system/loading';

const ConfigContext = createContext();

export const useConfig = () => {
  const requestState = useContext(ConfigContext);
  return requestState || {};
};

export const withConfigGuard = ({
  shouldRedirect,
  redirectTo,
  message = 'Vérifications en cours...',
}) => (Component) => (props) => {
  const requestState = useConfig();

  return (
    <Loading requestState={requestState}>
      {(rsConfig) => {
        const config = rsConfig.data && rsConfig.data.data;
        if (config) {
          if (shouldRedirect(config)) {
            setTimeout(() => navigate(redirectTo));
            return <div>{message}</div>;
          } else {
            return <Component {...props} config={config} />;
          }
        }
        return <div>Vérifications en cours...</div>;
      }}
    </Loading>
  );
};

export const ConfigProvider = ({ children }) => {
  const { requestState } = useAxiosNow({
    url: routes.config(),
  });
  return (
    <ConfigContext.Provider value={requestState}>
      {children}
    </ConfigContext.Provider>
  );
};
