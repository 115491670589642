import { useReducer, useState, useRef } from 'react';
import Axios from 'axios';
import { useEffect } from 'react';

// status: beforeLoading, loading, loaded, error

const requestLoadingReducer = (_, { type, data = {} } = {}) => {
  switch (type) {
    case 'requestStarted':
      return {
        status: 'loading',
        data: null,
        error: null,
      };
    case 'receivedData':
      return {
        status: 'loaded',
        data,
        error: null,
      };
    case 'errorReceived':
      return {
        status: 'error',
        data: null,
        error: data,
      };
    default:
      console.log(
        `Unknown action: ${JSON.stringify(type)} | data: \n ${JSON.stringify(
          data,
          null,
          2
        )}`
      );
      return;
  }
};

const load0 = (method, url, dispatch, otherAxiosConfig) => async ({
  payload = null,
} = {}) => {
  dispatch({ type: 'requestStarted' });
  try {
    const request = {
      url,
      method,
      data: payload,
      ...otherAxiosConfig,
    };

    const { data } = await Axios.request(request);
    dispatch({ type: 'receivedData', data });
    return { data };
  } catch (error) {
    dispatch({
      type: 'errorReceived',
      data: Boolean(error && error.response) ? error.response.data : null,
    });
    return { error };
  }
};

const useAxios = ({ url, method = 'get', ...otherAxiosConfig }) => {
  const [requestState, dispatch] = useReducer(requestLoadingReducer, {
    status: 'beforeLoading',
    data: null,
    error: null,
  });
  const load = load0(method, url, dispatch, otherAxiosConfig);

  return { requestState, load };
};

export const useAxiosNow = ({
  url,
  method = 'get',
  payload,
  retry = 1,
  ...otherAxiosConfig
}) => {
  const retriesContainer = useRef(retry);
  const [requestState, dispatch] = useReducer(requestLoadingReducer, {
    status: 'beforeLoading',
    data: null,
    error: null,
  });

  const load = load0(method, url, dispatch, otherAxiosConfig);

  const loadWithRetries = () => {
    const loadingPromise = payload ? load({ payload }) : load();
    loadingPromise.then(({ error }) => {
      if (error && retriesContainer.current > 0) {
        retriesContainer.current = retriesContainer.current - 1;
        loadWithRetries();
      }
    });
  };

  useEffect(loadWithRetries, [url]);

  return { requestState };
};

export const isLoading = (requestState) =>
  requestState ? requestState.status === 'loading' : false;

export default useAxios;
