import qs from 'qs';

const { GATSBY_API_URL } = process.env;

const routes = {
  csrf: () => `${GATSBY_API_URL}/sanctum/csrf-cookie`,
  login: () => `${GATSBY_API_URL}/login`,
  logout: () => `${GATSBY_API_URL}/logout`,
  activate: () => `${GATSBY_API_URL}/api/activateAccount`,

  configuration: () => `${GATSBY_API_URL}/api/configuration`,

  inscriptionLastWeek: () => `${GATSBY_API_URL}/api/inscriptions/lastWeek`,

  me: () => `${GATSBY_API_URL}/api/user`,
  team: () => `${GATSBY_API_URL}/api/user/all`,
  user: (id = '') =>
    id === ''
      ? `${GATSBY_API_URL}/api/user`
      : `${GATSBY_API_URL}/api/user/${id}`,

  news: () => `${GATSBY_API_URL}/api/news`,
  new: (id) => `${GATSBY_API_URL}/api/news/${id}`,

  saisons: () => `${GATSBY_API_URL}/api/saisons`,
  saison: (id) => `${GATSBY_API_URL}/api/saisons/${id}`,

  creneaux: () => `${GATSBY_API_URL}/api/creneau`,
  creneau: (id) => `${GATSBY_API_URL}/api/creneau/${id}`,
  creneauxNext: () => `${GATSBY_API_URL}/api/creneau/next`,

  sessions: () => `${GATSBY_API_URL}/api/sessions`,
  session: (id) => `${GATSBY_API_URL}/api/sessions/${id}`,

  familles: (searchParameters = {}) =>
    `${GATSBY_API_URL}/api/famille?${qs.stringify(searchParameters)}`,
  famillesSaisonCourante: (searchParameters = {}) =>
    `${GATSBY_API_URL}/api/famille/saisonCourante?${qs.stringify(
      searchParameters
    )}`,
  famillesCreneau: (searchParameters = {}) =>
    `${GATSBY_API_URL}/api/famille/byCreneau?${qs.stringify(searchParameters)}`,
  famillesSession: (searchParameters = {}) =>
    `${GATSBY_API_URL}/api/famille/bySession?${qs.stringify(searchParameters)}`,
  famillesMembresCA: () => `${GATSBY_API_URL}/api/famille/ca`,
  famille: (id) => `${GATSBY_API_URL}/api/famille/${id}`,
  presence: () => `${GATSBY_API_URL}/api/creneau/enfants`,

  enfants: () => `${GATSBY_API_URL}/api/enfant`,
  enfant: (id) => `${GATSBY_API_URL}/api/enfant/${id}`,
  enfantCreneauUpdate: (id) =>
    `${GATSBY_API_URL}/api/enfant/${id}/updateCreneau`,

  inscriptionSave: (familleId) => `${GATSBY_API_URL}/api/inscriptions/book`,

  inscription: (inscriptionId) =>
    `${GATSBY_API_URL}/api/inscriptions/${inscriptionId}`,

  paiement: (paiementId) => `${GATSBY_API_URL}/api/paiement/${paiementId}`,

  statistics: (saisonId) =>
    `${GATSBY_API_URL}/api/stats/general${
      saisonId ? '?saison=' + saisonId : ''
    }`,

  issues: () => `${GATSBY_API_URL}/api/issues`,
  fixVaccination: () => `${GATSBY_API_URL}/api/issues/fixVaccination`,
  fixCertificat: () => `${GATSBY_API_URL}/api/issues/fixCertificat`,
  fixReduction: () => `${GATSBY_API_URL}/api/issues/fixReduction`,

  //Password reset
  resetStep1: () => `${GATSBY_API_URL}/password/email`,
  resetStep2: () => `${GATSBY_API_URL}/password/reset`,

  // Inscription
  getAvailableCreneaux: (email = '') =>
    `${GATSBY_API_URL}/api/inscription/creneauxEtSessions${
      email === '' ? '' : '?' + qs.stringify({ prebookId: email })
    }`,
  prebook: (email = '') =>
    `${GATSBY_API_URL}/api/inscription/preinscription${
      email === '' ? '' : '?' + qs.stringify({ id: email })
    }`,
  prebookReinscription: (email = '') =>
    `${GATSBY_API_URL}/api/inscription/prereinscription${
      email === '' ? '' : '?' + qs.stringify({ id: email })
    }`,
  makeStripeSession: (email) =>
    `${GATSBY_API_URL}/api/inscription/makeStripeSession?${qs.stringify({
      id: email,
    })}`,

  // public data
  saisonProchaine: () => `${GATSBY_API_URL}/api/saisonProchaine`,
  config: () => `${GATSBY_API_URL}/api/config`,
  creneauxDispos: () => `${GATSBY_API_URL}/api/creneauxDispos`,

  // Familles endpoints
  userFamille: () => `${GATSBY_API_URL}/api/user/famille`,
  password: () => `${GATSBY_API_URL}/api/user/famille/password`,

  // Mailing
  mailingFilters: () => `${GATSBY_API_URL}/api/mailing/filters`,
  mailingFamillesCreneau: () =>
    `${GATSBY_API_URL}/api/mailing/famillesByCreneaux`,
  mailingFamillesSessions: () =>
    `${GATSBY_API_URL}/api/mailing/famillesBySessions`,
  mailingFamillesIssues: () => `${GATSBY_API_URL}/api/mailing/famillesByIssue`,
  mailingFamillesSaison: () => `${GATSBY_API_URL}/api/mailing/famillesBySaison`,
  mailingPreview: () => `${GATSBY_API_URL}/api/mailing/preview`,
  mailingSend: () => `${GATSBY_API_URL}/api/mailing/send`,
  mailings: (searchParameters) =>
    `${GATSBY_API_URL}/api/mailing?${qs.stringify(searchParameters)}`,
  mailingView: (id) => `${GATSBY_API_URL}/api/mailing/${id}`,
};

export default routes;
