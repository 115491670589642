import React, { createContext, useContext, useReducer } from 'react';
import { navigate } from 'gatsby';

import axios from 'axios';

import routes from '../../config/routes';

const AuthContext = createContext();

export const useAuth = () => {
  const value = useContext(AuthContext);
  return (
    value || {
      login: () => {},
      logout: () => {},
      init: () => {},
      loginState: {},
    }
  );
};

const loginStateReducer = (state, action = {}) => {
  switch (action.type) {
    case 'load':
      return { status: 'loading', error: false, userProfile: null };
    case 'profileReceived':
      return {
        userProfile: action.userProfile,
        status: 'loggedIn',
        error: false,
      };
    case 'invalidSession':
      return {
        userProfile: null,
        status: 'loggedOut',
        error: false,
      };
    case 'invalidCredentials':
      return {
        userProfile: null,
        status: 'loggedOut',
        error: true,
      };
    default:
      console.error('Invalid action type');
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [loginState, dispatch] = useReducer(loginStateReducer, {
    status: 'init',
    userProfile: null,
    error: false,
  });

  /**
   * @param credentials { email, password }
   */
  const login = async ({ email, password }) => {
    dispatch({ type: 'load' });

    const formData = new FormData();
    formData.set('email', email);
    formData.set('password', password);

    try {
      await axios.get(routes.csrf());
      await axios.post(routes.login(), formData);
      const userProfileResponse = await axios.get(routes.me());
      dispatch({
        type: 'profileReceived',
        userProfile: userProfileResponse.data,
      });

      if (userProfileResponse.data.role === 'famille') {
        navigate('/famille/');
      } else if (userProfileResponse.data.role === 'admin') {
        navigate('/admin');
      }
    } catch (e) {
      if (e.response && e.response.status === 422) {
        dispatch({
          type: 'invalidCredentials',
        });
      }
      console.log('Cannot connect');
      console.log(JSON.stringify(e, null, 2));
    }
  };

  const logout = ({ to = '/logout' } = {}) => {
    dispatch({ type: 'invalidSession' });
    axios.post(routes.logout());
    navigate(to);
  };

  const init = async () => {
    // Check we don't init twice
    if (loginState.status !== 'init') return;

    dispatch({ type: 'load' });

    try {
      const userProfileResponse = await axios.get(routes.me());

      dispatch({
        type: 'profileReceived',
        userProfile: userProfileResponse.data,
      });
    } catch (e) {
      dispatch({
        type: 'invalidSession',
      });
    }
  };

  return (
    <AuthContext.Provider value={{ loginState, login, logout, init }}>
      {children}
    </AuthContext.Provider>
  );
};
