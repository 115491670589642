import React from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import routes from 'src/config/routes';

import { AuthProvider } from './src/components/authentication';
import { ConfigProvider } from 'src/components/configuration';

import './src/style/index.css';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // 401 is unauthenticated
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.config.url !== routes.me()
    ) {
      navigate('/login');
    } else {
      return Promise.reject(error);
    }
  }
);

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <ConfigProvider>{element}</ConfigProvider>
  </AuthProvider>
);
