import React from 'react';
import { Grid as GridLoader } from 'svg-loaders-react';

import ErrorAlert from './alerts/error';

const SimpleLoading = () => (
  <div className="w-full px-2 mx-auto mt-8 text-lg font-thin text-center md:px-0 md:mt-15">
    <div className="flex flex-row content-center justify-center align-middle">
      Chargement en cours
      <GridLoader fill="rgb(15, 191, 228)" className="w-4 h-4 my-auto ml-4" />
    </div>
  </div>
);
const SimpleError = ({ error }) => (
  <ErrorAlert
    message={error ? error.message : 'Une error est survenue'}
    errors={error ? error.errors : []}
    className="my-4"
  />
);

const Loading = ({
  requestState = {},
  children: renderFunction,
  loadingComponent: LoadingComponent = SimpleLoading,
  errorComponent: ErrorComponent = SimpleError,
}) => {
  if (requestState.status === 'loaded') {
    return renderFunction(requestState);
  } else if (requestState.status === 'error') {
    return <ErrorComponent error={requestState.error} />;
  } else {
    return <LoadingComponent requestState={requestState} />;
  }
};

export const LoadingMany = ({
  requestStates = [],
  children: renderFunction,
  loadingComponent: LoadingComponent = SimpleLoading,
  errorComponent: ErrorComponent = SimpleError,
}) => {
  const errorRs = requestStates.find((s) => s.status === 'error');

  if (errorRs) {
    return <ErrorComponent error={errorRs.error} />;
  }

  const isLoading = Boolean(
    requestStates.find(
      (s) => s.status === 'loading' || s.status === 'beforeLoading'
    )
  );

  if (isLoading) {
    return <LoadingComponent />;
  } else {
    return renderFunction(requestStates);
  }
};

export default Loading;
